import reportWebVitals from './reportWebVitals';
import ReactDOM from 'react-dom/client';
import { lazy } from '@loadable/component'
import React, { Suspense } from "react";
import './globals.css'
import { PublicClientApplication, EventType, AccountInfo, AuthenticationResult } from '@azure/msal-browser';
import { loginRequest, msalConfig } from './authConfig';
import { LazyLoader } from 'components/common';
const App = lazy(() => import('./App'))

/**
 * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
 * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
export const msalInstance = new PublicClientApplication(msalConfig);

(async () => {
    await msalInstance.initialize();

    // Default to using the first account if no account is active on page load
    const activeAccount = msalInstance.getActiveAccount();
    if (!activeAccount && msalInstance.getAllAccounts().length > 0) {
        msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0] as AccountInfo);
    }
})();

// Listen for sign-in event and set active account
msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload && 'account' in event.payload) {
        const account = (event.payload as AuthenticationResult).account;
        msalInstance.setActiveAccount(account);
        msalInstance.acquireTokenSilent(loginRequest).then((response) => { localStorage.setItem('token', response.accessToken) });
    }
});
ReactDOM.createRoot(document.getElementById('root')!).render(<Suspense fallback={<LazyLoader />}>
    <App instance={msalInstance} />
</Suspense>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
