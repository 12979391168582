import React from 'react'
import Loader from './Loader'

function LazyLoader() {
  return (
    <div className='flex h-screen w-screen items-center justify-center'>
      <Loader />
    </div>
  )
}

export default LazyLoader
